import React, { useState } from "react";
import "./App.css";
import BioModal from "./BioModal";

const About = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <section
      id="about"
      className="w-screen h-full flex-shrink-0 snap-start flex items-center justify-center bg-gray-100 p-8"
    >
      <div className="text-center max-w-4xl w-full bg-white rounded-lg shadow-xl p-8">
        <h1 className="text-5xl font-bold mb-6">About</h1>
        <h2 className="text-3xl mb-8">
          We Serve St. Louis and Surrounding Missouri Counties
        </h2>
        <p className="text-2xl mb-6">
          Patrick Driscoll REALTOR, Broker
          <br />
          Driscoll Co, LLC
        </p>
        <p className="text-xl mb-8">
          My career spanning nearly two decades since 2004, I have been working
          on accumulated a wealth of knowledge and expertise in the real estate
          industry.
        </p>
        <button
          onClick={openModal}
          className="bg-red-700 text-white px-6 py-2 rounded-full font-bold hover:bg-red-800 transition duration-300 mb-8"
        >
          My Full Bio
        </button>
        <ul className="text-xl list-disc list-inside text-left mb-8">
          <li>Expertise in residential real estate</li>
          <li>Specialization in commercial properties</li>
          <li>Proficient in investment property services</li>
          <li>Experienced in landlord/tenant representation</li>
        </ul>
      </div>
      <BioModal isOpen={isModalOpen} onClose={closeModal} />
    </section>
  );
};

export default About;
