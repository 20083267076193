import React, { useState } from "react";
import "./App.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <section
      id="contact"
      className="w-screen h-full flex-shrink-0 snap-start flex items-center justify-center bg-gray-100 p-8"
    >
      <div className="max-w-4xl w-full bg-white rounded-lg shadow-xl p-8">
        <h2 className="text-4xl font-bold text-red-600 mb-6 text-center">
          Contact
        </h2>

        <div className="grid md:grid-cols-2 gap-8">
          {/* Contact Form */}
          <div>
            <h3 className="text-2xl font-semibold text-gray-800 mb-4">
              Get in Touch
            </h3>
            <form
              role="form"
              action="https://formsubmit.co/driscollcorp@gmail.com"
              method="POST"
            >
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="message"
                  className="block text-gray-700 font-bold mb-2"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 transition duration-300"
              >
                Send
              </button>
            </form>
          </div>

          {/* Contact Information */}
          <div>
            <h3 className="text-2xl font-semibold text-gray-800 mb-4">
              Information
            </h3>
            <p className="text-gray-600 mb-2">Patrick Driscoll</p>
            <p className="text-gray-600 mb-2">
              REALTOR, Broker Driscoll Co, LLC
            </p>
            <p className="text-gray-600 mb-2">
              St. Louis and Surrounding Missouri Counties
            </p>
            <p className="text-gray-600 mb-2">
              Email: patrick@driscollcorp.com
            </p>
            <p className="text-gray-600 mb-4">Phone: (314) 922-3810</p>

            <h3 className="text-2xl font-semibold text-gray-800 mb-4 mt-8">
              Services
            </h3>
            <ul className="list-disc list-inside text-gray-600">
              <li>Residential Real Estate</li>
              <li>Commercial Real Estate</li>
              <li>Investment Properties</li>
              <li>Landlord/Tenant Representation</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
