import React, { useState } from "react";

const SideNav = ({ scrollContainerRef }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  const handleNavClick = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId.toLowerCase());
    if (targetElement && scrollContainerRef && scrollContainerRef.current) {
      const containerRect = scrollContainerRef.current.getBoundingClientRect();
      const targetRect = targetElement.getBoundingClientRect();
      const scrollLeft =
        targetRect.left -
        containerRect.left +
        scrollContainerRef.current.scrollLeft;

      scrollContainerRef.current.scrollTo({
        left: scrollLeft,
        behavior: "smooth",
      });
    }
    toggleNav();
  };

  return (
    <>
      {/* Logo button with transparent background */}
      <button
        onClick={toggleNav}
        className="fixed top-4 left-4 z-50 p-1 bg-transparent transition-transform duration-300 ease-in-out hover:scale-110"
      >
        <img
          src="./logo.png"
          alt="Driscoll Co Logo"
          className="w-24 h-24 object-contain"
        />
      </button>

      {/* Overlay */}
      <div
        className={`fixed inset-0 bg-black transition-opacity duration-300 ease-in-out ${
          isOpen ? "opacity-50 z-40" : "opacity-0 pointer-events-none"
        }`}
        onClick={toggleNav}
      ></div>

      {/* Side Navigation */}
      <nav
        className={`fixed top-0 left-0 bottom-0 w-64 bg-white z-50 transform transition-all duration-300 ease-in-out ${
          isOpen
            ? "translate-x-0 scale-100 opacity-100"
            : "-translate-x-full scale-95 opacity-0"
        }`}
      >
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-6 text-red-600 hover:font-bold hover:scale-110">
            Driscoll Co. RE
          </h2>
          <ul className="space-y-4">
            {[
              "Home",
              "Commercial",
              "Investment",
              "Residential",
              "About",
              "Contact",
            ].map((section) => (
              <li key={section}>
                <a
                  href={`#${section.toLowerCase()}`}
                  className="text-gray-700 hover:text-black-600 transition-all duration-300 ease-in-out inline-block"
                  onClick={(e) => handleNavClick(e, section)}
                >
                  <span className="transform transition-all duration-300 ease-in-out inline-block hover:font-bold hover:scale-110">
                    {section}
                  </span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default SideNav;
