import React, { useRef, useEffect } from "react";
import SideNav from "./SideNav";
import Home from "./Home";
import Commercial from "./Commercial";
import Investment from "./Investment";
import Residential from "./Residential";
import About from "./About";
import Contact from "./Contact";

const ScrollingApp = () => {
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const handleScroll = (event) => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollLeft += event.deltaY;
      }
    };

    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("wheel", handleScroll, { passive: false });
    }

    return () => {
      if (container) {
        container.removeEventListener("wheel", handleScroll);
      }
    };
  }, []);

  return (
    <div className="h-screen overflow-hidden">
      <SideNav scrollContainerRef={scrollContainerRef} />
      <div 
          ref={scrollContainerRef}
          className="flex overflow-x-auto snap-x snap-mandatory h-full scrollbar-hide"
          style={{ scrollBehavior: 'smooth', scrollSnapType: 'x mandatory' }}
        >
        <Home />
        <Commercial />
        <Investment />
        <Residential />
        <About />
        <Contact />
      </div>
    </div>
  );
};

export default ScrollingApp;
