import React from "react";
import "./App.css";
import commercialImage from "./img/DC-Commercial.png";

const Commercial = () => {
  return (
    <section
      id="commercial"
      className="w-screen h-full flex-shrink-0 snap-start flex items-center justify-center bg-gray-100 p-8"
    >
      <div className="text-center max-w-4xl w-full bg-white rounded-lg shadow-xl p-8">
        <h1 className="text-5xl font-bold mb-6">Commercial Real Estate</h1>
        <h2 className="text-3xl mb-8">
          St. Louis and Surrounding Missouri Counties
        </h2>
        <p className="text-2xl mb-8">
          Comprehensive Commercial Real Estate Services
        </p>
        <div className="flex flex-col md:flex-row items-start justify-between">
          <ul className="text-xl list-disc list-inside text-left mt-10 md:w-1/2">
            <li>Office space leasing and sales</li>
            <li>Retail property transactions</li>
            <li>Industrial real estate solutions</li>
            <li>Land acquisition and development</li>
            <li>Market analysis and valuation</li>
          </ul>
          <img
            src={commercialImage}
            alt="Commercial Real Estate"
            className="w-full md:w-1/2 h-auto rounded-lg shadow-md"
          />
        </div>
        <p className="text-xl mt-8">
          At Driscoll Co, we leverage our extensive experience and market
          knowledge to provide tailored commercial real estate services that
          meet your specific business needs and objectives.
        </p>
      </div>
    </section>
  );
};

export default Commercial;
