import React from "react";
import "./App.css";
import residentialImage from "./img/house.svg";

const Residential = () => {
  return (
    <section
      id="residential"
      className="w-screen h-full flex-shrink-0 snap-start flex items-center justify-center bg-gray-100 p-8"
    >
      <div className="text-center max-w-4xl w-full bg-white rounded-lg shadow-xl p-8">
        <h1 className="text-5xl font-bold mb-6">Residential Real Estate</h1>
        <h2 className="text-3xl mb-8">
          St. Louis and Surrounding Missouri Counties
        </h2>
        <p className="text-2xl mb-8">
          Comprehensive Residential Real Estate Services
        </p>
        <div className="flex flex-col md:flex-row items-start justify-between">
          <img
            src={residentialImage}
            alt="Residential Real Estate"
            className="w-full md:w-1/2 h-auto rounded-lg shadow-md"
          />
          <ul className="text-xl list-disc list-inside text-left m-10 md:w-1/2">
            <li>Buyer representation</li>
            <li>Seller representation</li>
            <li>Tenant representation</li>
            <li>CMA analysis</li>
            <li>First-time homebuyer guidance</li>
            <li>Relocation assistance</li>
          </ul>
        </div>
        <p className="text-xl mt-8">
          At Driscoll Co, we leverage our extensive experience since 2004 to
          provide tailored residential Real Estate services. Whether you're
          buying, selling, or renting, our expert knowledge of the St. Louis
          area ensures you receive top-notch representation to meet your
          specific needs and objectives.
        </p>
      </div>
    </section>
  );
};

export default Residential;
