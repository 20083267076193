import React from "react";
import "./App.css";
import investmentImage from "./img/Home.jpg";

const Investment = () => {
  return (
    <section
      id="investment"
      className="w-screen h-full flex-shrink-0 snap-start flex items-center justify-center bg-gray-100 p-8"
    >
      <div className="text-center max-w-4xl w-full bg-white rounded-lg shadow-xl p-8">
        <h1 className="text-5xl font-bold mb-6">Investment Properties</h1>
        <h2 className="text-3xl mb-8">
          St. Louis and Surrounding Missouri Counties
        </h2>
        <p className="text-2xl mb-8">
          Comprehensive Investment Property Services
        </p>
        <div className="flex flex-col md:flex-row items-start justify-between">
          <ul className="text-xl list-disc list-inside text-left mt-10 md:w-1/2">
            <li>Single-family investment properties</li>
            <li>Multi-family investment properties</li>
            <li>Multi-tenant investment properties</li>
            <li>Property valuation and market analysis</li>
            <li>Investment strategy consultation</li>
            <li>Portfolio management assistance</li>
          </ul>
          <img
            src={investmentImage}
            alt="Investment Properties"
            className="w-full md:w-1/2 h-auto rounded-lg shadow-md"
          />
        </div>
        <p className="text-xl mt-8">
          <span className="font-bold text-1xl text-black-600 px-2 py-1 rounded">
            Driscoll Co. RealEstate Services
          </span>{" "}
          we leverage our extensive experience since 2004 to provide tailored
          investment property services. Whether you're looking for
          single-family, multi-family, or multi-tenant investment opportunities,
          we have the expertise to guide you through the process and help you
          make informed decisions.
        </p>
      </div>
    </section>
  );
};

export default Investment;
