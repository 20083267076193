import React from "react";

const BioModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg max-w-3xl max-h-[90vh] overflow-y-auto">
        <button onClick={onClose} className="float-right text-2xl font-bold">
          &times;
        </button>
        <h2 className="text-3xl font-bold mb-4">Patrick Driscoll, Broker</h2>
        <p className="text-xl mb-8">
          Patrick Driscoll is a dedicated REALTOR and Broker with Driscoll Co,
          LLC, proudly serving St. Louis and the surrounding Missouri counties.
          A long-time resident of Kirkwood, Missouri, Patrick has deep roots in
          the St. Louis metropolitan area and an intimate understanding of its
          diverse neighborhoods and real estate markets. With a career spanning
          nearly two decades, Patrick has been a licensed real estate agent
          since 2004. His extensive experience covers a wide range of real
          estate services, including residential, commercial, and investment
          properties. Patrick's expertise also extends to landlord and tenant
          representation, making him a versatile professional capable of
          handling various real estate needs. As a Kirkwood local, Patrick
          brings a unique perspective to his work, combining his professional
          knowledge with a genuine love for the St. Louis community. He
          leverages his familiarity with the area to help clients find the
          perfect properties in charming neighborhoods like Kirkwood and beyond.
          Patrick's commitment to his clients is evident in his comprehensive
          approach to real estate. Whether you're a first-time homebuyer, a
          seasoned investor, or a business owner looking for the ideal
          commercial space, Patrick's diverse skill set and local insights
          ensure that you receive top-notch service tailored to your specific
          needs. When not assisting clients, Patrick can often be found enjoying
          the many amenities Kirkwood and St. Louis have to offer, from
          exploring local parks to participating in community events. His
          passion for the area shines through in his work, making him not just a
          real estate agent, but a true ambassador for the St. Louis region.
        </p>
      </div>
    </div>
  );
};

export default BioModal;
