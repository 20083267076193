import React, { useRef } from "react";
import "./App.css";
import backgroundImage from "./img/A.jpeg";

const Home = () => {
  const scrollContainerRef = useRef(null);

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement && scrollContainerRef.current) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section
      id="home"
      ref={scrollContainerRef}
      className="w-screen h-screen flex-shrink-0 snap-start relative"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="text-center text-white max-w-4xl px-4">
          <h1 className="text-5xl md:text-6xl font-bold mb-6">
            Driscoll Co. Real Estate
          </h1>
          <h2 className="text-3xl md:text-4xl mb-8">
            St. Louis and Surrounding Missouri Counties
          </h2>
          <p className="text-xl md:text-2xl mb-8 duration-300 ease-in-out transform hover:scale-110 hover:shadow-lg">
            Expert Real Estate Services Since 2004
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
            <div className="bg-white bg-opacity-10 p-4 rounded-lg">
              <h3 className="text-2xl font-semibold mb-2">Commercial</h3>
              <p>Commercial Real Estate services</p>
            </div>
            <div className="bg-white bg-opacity-10 p-4 rounded-lg">
              <h3 className="text-2xl font-semibold mb-2">Investment</h3>
              <p>
                Single, Multi-family or multi-tenant investment property
                services
              </p>
            </div>
            <div className="bg-white bg-opacity-10 p-4 rounded-lg">
              <h3 className="text-2xl font-semibold mb-2">Residential</h3>
              <p>Buyer, seller, or tenant representation</p>
            </div>
            <div className="bg-white bg-opacity-10 p-4 rounded-lg">
              <h3 className="text-2xl font-semibold mb-2">Expertise</h3>
              <p>
                Residential, Commercial, Investment, and Landlord/Tenant
                representation
              </p>
            </div>
          </div>
          <p className="text-xl mb-8">
            Patrick Driscoll, REALTOR and Broker at Driscoll Co, LLC, has been a
            Licensed Agent since 2004. Familiar with various markets including
            residential, commercial, investment, and landlord/tenant
            representation.
          </p>
          <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-6">
            <button
              onClick={() => scrollToSection("commercial")}
              className="bg-red-700 text-white px-8 py-3 rounded-full text-lg font-semibold 
                   hover:bg-red-800 transition-all duration-300 ease-in-out
                   transform hover:scale-110 hover:shadow-lg"
            >
              Explore Services
            </button>
            <button
              onClick={() => scrollToSection("contact")}
              className="bg-white text-red-700 px-8 py-3 rounded-full text-lg font-semibold 
                   hover:bg-gray-100 transition-all duration-300 ease-in-out
                   transform hover:scale-110 hover:shadow-lg"
            >
              Contact
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
